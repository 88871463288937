const productList = [
    {
        id: 1, type: 1, title: '水质监测仪器', value: 'CODcr在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在试样中加入定量的重铬酸钾溶液，在强酸性介质中，以硫酸银作为催化剂，利用独特稀释进样技术降低氯离子浓度，并使用联合掩蔽剂掩蔽高浓度氯离子，经高温高压消解后，用分光光度法测定COD浓度值。', parameters: [
            { '测量方法': '依据标准HJ828-2017《水质-化学耗氧量测定-重铬酸盐法》' }, { '测量量程': '0-200 mg/L ；0-500 mg/L ；0-1000 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为165 ℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±3%' }, { '检出限': '10mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±3%' }, { '实际水样比对': '±8%' }, { '恒温时间': '900秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '39分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 2, type: 1, title: '水质监测仪器', value: '氨氮在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在碱性介质（pH =11.7)中，试样中的氨、铵离子与二氯异氰脲酸钠溶液释放出来的次氯酸根反应生成氯胺；在40℃和亚硝基铁氰化钠存在的条件下，氯胺与水杨酸盐反应形成蓝绿色化合物，于特定波长处用分光光度计测量吸光度。', parameters: [
            { '测量方法': 'HJ536-2009水质 氨氮-水杨酸分光光度法（可定制纳氏方法）' }, { '测量量程': '0-2 mg/L ；0-10 mg/L ；0-50 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为45℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±3%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±3%' }, { '实际水样比对': '±8%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 3, type: 1, title: '水质监测仪器', value: '总磷（TP）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在高温、高压条件下，用过硫酸钾消解试样，试样中所有含磷化合物全部转为正磷酸盐（测量正磷酸盐无需此步骤）。在5%-8%的硝酸溶液中，正磷酸盐与偏钒酸铵和钼酸铵形成可溶性的磷钒钼黄络合物，在特定波长处测定其吸光度，该吸光度与试样的正磷酸盐浓度成线性关系。依此关系，可将该吸光度转化为试样的总磷（TP）或正磷酸盐（PO4）浓度值。', parameters: [
            { '测量方法': '钼酸铵分光光度法（HJ670-2013）' }, { '测量量程': '0-2 mg/L ；0-10 mg/L ；0-50 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为120℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±3%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '4.5mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '±8%' }, { '恒温时间': '900秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '39分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 4, type: 1, title: '水质监测仪器', value: '总氮（TN）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '（间苯二酚方法）在120℃的碱性介质中，用过硫酸钾做氧化剂，不仅可以将水中的氨氮和亚硝酸盐氮转化为硝酸盐，同时也将大部分的有机氮转化为硝酸盐，然后用紫外分光光度计于波长特定波长处测量吸光度。', parameters: [
            { '测量方法': '碱性过硫酸钾消解紫外分光光度法' }, { '测量量程': '：0-5 mg/L ；0-20 mg/L ；0-50 mg/L；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±5%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '±10%' }, { '恒温时间': '900秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '51分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 5, type: 1, title: '水质监测仪器', value: '总氮（TN）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '（光谱检测方法；需定制）在125°C的碱性介质中，用过硫酸钾做氧化剂，不仅可以将水中的氨氮和亚硝酸盐氮转化为硝酸盐，同时也将大部分的有机氮转化为 硝酸盐，通过光谱仪在220nm和270nm处测定水中总氮的吸光度值。', parameters: [
            { '测量方法': '光谱检测法' }, { '测量量程': '0-5 mg/L ；0-20 mg/L ；0-50 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±5%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '±10%' }, { '恒温时间': '900秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '51分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 6, type: 1, title: '水质监测仪器', value: '六价铬（Cr6+）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '本产品采用二苯碳酰二肼分光比色方法测定，水样和调节剂混合后，六价铬在酸性环境和指示剂存在的情况下，与指示剂反应生成一种带色络合物，分析仪检测此颜色的变化，并把这种变化换算成六价铬值输出来。生成的带色络合物量相当于六价铬量。', parameters: [
            { '测量方法': '二苯碳酰二肼分光光度比色法' }, { '测量量程': '0-2 mg/L ；0-20 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±8%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±0.3mg/L' }, { '实际水样比对': '浓度≤0.400mg/L时，比对检测误差≤0.06mg/L,浓度＞0.400mg/L时比对检测误差  ≤±15%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '25分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 7, type: 1, title: '水质监测仪器', value: '总铬（TCr）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '本产品采用二苯碳酰二肼分光比色法测定，水样和强氧化剂混合后，把三价铬氧化成六价铬，六价铬在酸性环境和指示剂存在情况下，与指示剂反应生成一种带色络合物，分析仪检测此颜色的变化，并把这种变化换算成总铬值输出来。生成的带色络合物量相当于总铬量。', parameters: [
            { '测量方法': '二苯碳酰二肼分光光度比色法' }, { '测量量程': '0-2 mg/L ；0-20 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±8%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±5%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '25分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 8, type: 1, title: '水质监测仪器', value: '总镍（TNi）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '水样和调节剂混合后，镍离子在氨性环境和指示剂存在的情况下，与指示剂反应生成一种带色络合物，分析仪检测此颜色的变化，并把这种变化换算成镍离子值输出，生成带色络合物量相当于镍离子含量。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-2 mg/L ；0-20 mg/L ' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±8%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±8%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 9, type: 1, title: '水质监测仪器', value: '总铜（TCu）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在碱性环境下（PH=8-10），铜与显色剂作用生成黄棕色络合物；此络合物可用四氯化碳或三氯甲烷萃取，在特定波长处测量吸光度。颜色可稳定1h。', parameters: [
            { '测量方法': '二乙基二硫代氨基甲酸钠分光光度法' }, { '测量量程': '0-2 mg/L ；0-20 mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 10, type: 1, title: '水质监测仪器', value: '总锰（TMn）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '水样通过取样系统注入消解池中，然后按顺序先后依次注入氧化剂；然后混合液进行高温消解，将锰的不同价态都转化为二价锰，其次加入显色剂，在一定波长下进行比色测定，再根据朗伯-比尔定律计算出来总锰的含量。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': ' 0-15mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±9%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 11, type: 1, title: '水质监测仪器', value: '总铁（TFe）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '水样通过取样系统注入消解池中，添加酸性试剂，然后混合后的样品加热至95℃，使悬浮态的铁溶解；其次再注入缓冲剂，调整到合适的PH值进行还原，将三价铁还原为二价，最后加入显色剂，铁离子和显色剂生成紫色复合物，然后用比色计在指定波长下测量复合物的吸光度，从而计算出来总铁的含量。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-10mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为95℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 12, type: 1, title: '水质监测仪器', value: '总铅（TPb）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '水样通过取样系统注入消解池中，然后按顺序先后依次注入酸性药剂；然后混合液经加热消解，将不同价态的铅转化为二价铅；然后注入显色剂，经过反应后，在指定波长下根据显色颜色的深浅测定总铅的含量。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-5 mg/L ' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±8%' }, { '检出限': '0.005mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 13, type: 1, title: '水质监测仪器', value: '总锌（TZn）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在一定的PH环境下锌与特定试剂反应生成络合物，最大吸收于指定波长处。在50毫升溶液中含有0-120微克锌，符合皮耳定律。络合物可稳定数小时，放置时间长有微弱的褪色。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-10 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.001mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 14, type: 1, title: '水质监测仪器', value: '总砷（TAs）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在新生态氢存在下，五价砷被转换为三价，用显色剂吸收砷，生成红色络合物，在特定波长下，测量吸光度转换为砷的浓度值。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-2 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±5%' }, { '检出限': '0.001mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±5%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 15, type: 1, title: '水质监测仪器', value: '总汞（THg）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在95度用高锰酸钾加热消解水样，在所有汞化合物转换为二价汞，用还原剂氧化还原过量的氧化剂，在特定的PH条件下，汞离子与显色剂生成络合物，用指定波长检测吸光度，转换为汞的浓度。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-1 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.0001mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 16, type: 1, title: '水质监测仪器', value: '总银（TAg）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '在一定PH条件下，银与显色剂生成红色络合物，络合物在最大吸收波长处检测吸光度，转换成银的浓度。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-5 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±3%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 17, type: 1, title: '水质监测仪器', value: '总镉（TCd）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '水样经消化后，在碱性溶液中镉离子与6-溴苯并噻唑偶氮萘酚形成红色络合物，溶于三氯甲烷，与标准系列比较定量。在特定波长下检测吸光度，从而换算出镉的浓度。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-1 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.0001mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 18, type: 1, title: '水质监测仪器', value: '氰化物（CN）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '向试样中加入磷酸，在一定PH条件下，加热消解，利用金属离子和试剂络合物与氰离子络合能力更强的特点，使络合氰化物离子解出氰离子，并以氰化氢形式被蒸馏出来，用氢氧化钠吸收，检测浓度。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-0.5 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为125℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.001mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±1%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 19, type: 1, title: '水质监测仪器', value: '氟化物在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '氟离子在一定PH值条件下，与乙酸盐和显色剂反应，生产固定颜色络合物。络合物在一定波长下有最大吸收，吸光度与氟离子浓度成正比。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-10 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为120℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.01mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±3%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 20, type: 1, title: '水质监测仪器', value: '挥发酚在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '缓冲溶液需要低温避光保存，PH控制在9.6-11.5范围内，否则吸光度偏高。当水样含挥发性酸时，可使馏出液PH降低，应向馏出液中加入氨水调至中性后再加缓冲液。否则当PH＜9.8时，某些苯胺类化合物可与显色剂显色而干扰酚的测定。显色剂对实验的准确性相当重要，国际上都提到试剂的提纯，因此选择什么样的试剂对实验很重要，根据实验经验，显色过程中每加一种试剂都要充分混均匀。显色时间的控制，实质就是显色环境的控制，控制水样和标准曲线环境一致，从而得到精确结果。', parameters: [
            { '测量方法': '分光光度法' }, { '测量量程': '0-10 mg/L' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为130℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.02mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±3%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 21, type: 1, title: '水质监测仪器', value: '高锰酸盐指数（CODMn）在线自动监测仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/water-product.png' , theory: '样品中加入已知量的高锰酸钾和硫酸，在高温下加热一定时间，高锰酸钾将样品中的某些有机物和无机物还原性物质氧化，反应后加入过量的草酸钠还原剩余的高锰酸钾，在用高锰酸钾标准溶液回滴过量的草酸钠，通过计算得到样品中高锰酸盐指数值。', parameters: [
            { '测量方法': '高锰酸钾氧化滴定法' }, { '测量量程': '0-20mg/L ；0-50mg/L ；量程可定制' }, { '显示输出': '配置有10.1寸彩色液晶触摸屏' }, { '显色温度': '推荐温度为160℃，可根据实际水样情况设置' }, { '数据导出': '测量值可以通过USB口导入U盘中保存' }, { '信号输出': 'RS485/RS232/USB接口/标配2路4-20mA输出/标配两路开关量输入、输出' }, { '环境要求': '温度可控的室内，建议温度（5-28℃），湿度≦90%（不结露）' }, { '仪器尺寸': '上机柜600*450*300mm；上机柜700*450*300mm' }, { '电源及功率': '（220V±22）V/AC，(50±0.5Hz)，5A，150W' }, { '示值误差': '≤±10%' }, { '检出限': '0.5mg/L' }, { '重复性': '≤±5%' }, { '废液量': '6mL/次' }, { '记忆效应': '≤±3%' }, { '实际水样比对': '≤±10%' }, { '恒温时间': '360秒' }, { '电压稳定性': '≤±5%' }, { '测量周期': '30分钟' }, { '校准周期': '任意指定时间' }, { '维护周期': '≥720h/次' }, { '维护工作量': '＜2小时/月' }, { '试剂消耗': '3个月/500ml' }
        ],
        characteristic: ['全新光电定量技术不受色度大、悬浮物多、气泡多等干扰的影响，可靠性及精度高，抗干扰能力强。', '检测精度高、测量下限低、仪器长期漂移量小。', '	具有一键自测功能和自我诊断功能。', '采用单通道高集成阀组，维护和清洗简单快捷。', '全天候联网功能，随时随地监控仪表运行状态。', '具备报警功能、质控功能和反控功能。', '具备自动清洗功能和自动校准功能。', '具备整点测量、间隔测量和外部控制测量。', '掉电恢复后自动工作。', '故障和缺试剂（样品）自动提示和自动复位功能。', '具备故障记录功能。', '可保存3年以上历史数据。', '满足清洗液与废液分开排液']
    },
    {
        id: 22, type: 2, title: '数采仪', value: '数据采集传输仪', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/scy-product.png' , theory: '', parameters: [
            { '触摸屏': '7寸，分辨率：800*480，16.7M色' }, { '有线传输': '以太网，1个10/100M自适应，RJ45端口' }, { '无线传输': '编码与频段：TDD-LTE B38/B39/B40/B41;FDD-LTE B1/B3/B8;TD-SCDMA  B34/B39;WCDMA/HSDPA/HSPA+  B1/B8;CDMA 1X/EVDO BC0;GSM/GPRS/EDGE 900/1800 MHz;传输方式：TCP、UDP、SMS;SIM/UMI卡型：标准SIM卡;天线接口：50Ω/SMA-K（阴头）;' }, { '模拟量输入': '8路，12位分辨率,0/4~20mA或0/1~5V输入' }, { '开关量输出': '4路，继电器输出，220V5A' }, { '开关量输入': '4路，光耦隔离，支持3~24V直流输入' }, { 'RS-232接口': '5路' }, { 'RS-485接口': '2路' }, { '内部存储容量': '32M' }, { '扩展存储接口': 'TF卡，最大支持32G' }, { '主电源': '（供电电压：220V±15%，50Hz;电源规格：12V/2A' }, { '备用电源': '充电电压：8.4V;充电电流：0-500mA;电池容量：4000mAH;其它要求：主电源断开工作大于6小时' }, { '接线端子': '2EDG5.08插拔式' }, { '尺寸': '320X380X80（单位mm）' }, { '材质': '烤漆钢板' }, { '其它要求': '不应有明显划痕、裂缝、变形和污染，仪器表面涂镀层应均匀，不应起泡、龟裂、脱落和磨损' }, { '环境温度': '-20-55摄氏度' }, { '环境湿度': '不高于95%RH' }, { '时钟精度': '5ppm' }, { '硬件看门狗': '支持' }, { 'MTBF': '>3000小时' }, { '数据导出': '通过TF卡导出csv格式文件' }, { '密码保护': '支持' }
        ],
        characteristic: []
    },
    {
        id: 23, type: 3, title: '流量计', value: '流量计', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/llj-product.png' , theory: '超声波明渠流量计测量是由安装在水面的超声波传感器，向水流表面发射一束超声波脉冲，此脉冲信号遇到水面后反射回来，能再被传感器接收到；从超声波发射到重新被接收，其时间与传感器到被测物体表面的距离成正比；仪表检测出该时间，并根据当前温度（传感器测量）空气的声速，计算出被测物体表面至传感器的距离（即空间距离），再进一步换算出液位值。液位表面的空水下距离W与声波的行程时间T成正比：W=V×T/2（其中V为声波水中速度）。用户自设定探头到槽底的高度H，仪表自动将脉冲行程时间T换算成相应的空间距离值W，再计算出H减W的差值即为测量的值L，根据液位高度，算出瞬时流量和累积流量。', parameters: [
            { '测量范围': '0.3~2m（根据高度换算成流量）或根据要求扩展；' }, { '显示方式': 'LCD' }, { '测量单位': '米（换算成流量）' }, { '分辨率': '0.001米' }, { '精确度': '±0.5 %FS' }, { '重复性': '±0.5%' }, { '功率': '≤3W' }, { '环境温度': '-20~50℃；相对湿度：不大于90%' }, { '输出': '1路4~20mA、1路RS485' }, { '开关输出': '三路继电器、容量250VAC/5A' }, { '供电电源': 'AC85~265V±10%  或9~36VDC' }
        ],
        characteristic: ['全智能、多功能、测量性能高，环境适应性强；', '多参数同时显示，高度、输出电流、时间、继电器状态；', '1路4-20mA变送输出、继电器高、低报警控制输出、RS485通信输出等各种变量输出', 'RS485上传功能；', '多种槽型选择；', '维护非常简单，三个月校正一次；', '采用多种标定方法，保证测量准确度；', '中英文菜单可选（选配）', '自设密码：用户可以自设或修改密码，以免无关人员进入造成误操作；']
    },
    {
        id: 24, type: 4, title: '水站项目集成', value: '水质在线监测岸边站（微型站）', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/wxz-product.png' , theory: 'XRP-200是一套以多参数水质自动分析仪系统为核心， 运用现代传感器技术、化学分析仪计时、自动控制技术、专用数据分析软件 和无线通讯网络构成的水质在线自动监测体系。典型应用：适用于各类河流、湖泊、水库、污染源、排污口的水质监测', parameters: [
            { '检测对象': '地表水、地下水、污水管网水和工业污水' }, { '检测指标': 'CODmn、NH4-N、总磷、TN、溶解氧、电导率、pH、浊度、水温' }, { '测量精度': '符合国标' }, { '分析机制': '化学法+光学法+离子选择法' }, { '测量周期': '＞1h 可调' }, { '数据传输': '4G全屏/Modbus RTU' }, { 'CODmn检测范围和分辨率': '0～300mg/L,0.01mg/L' }, { 'NH4-N检测范围和分辨率': '0～20mg/L,0.1mg/L' }, { '总磷检测范围和分辨率': '0～50mg/L,0.01mg/L' }, { 'TN检测范围和分辨率': '0～50mg/L,0.01mg/L' }, { '电导率检测范围和分辨率': '0～200mS/cm,0.01uS/cm' }, { '溶解氧检测范围和分辨率': '0～20mg/L,0.01mg/L' }, { '浊度检测范围和分辨率': '0～4000NTU,0.01NTU' }, { 'pH检测范围和分辨率': '0～14,0.01' }, { '温度检测范围和分辨率': '（0～50）℃,0.1℃' }
        ],
        characteristic: ['集成度高，可监测14个参数：常规五参、COD、氨氮、TP等', '占地1平方左右，无需土地审批', '采用模块化设计，根据需求定制不同的监测参数', '流路经过特殊设计，不易堵塞，易于维护', '试剂用量小，1个月支持250个样的频率测量', '如业主有移动要求，可直接移动至其他监测位置', '实时数据远程传输功能']
    },
    {
        id: 25, type: 5, title: '传感器', value: 'OSN-COD01 COD传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/codcgq-product.png' , theory: 'OSN-COD01是我司推出的新型UV254COD传感器，采用紫外吸收法，汇聚行业多年应用开发经验，传感器体积小巧，集成内部自清洁，表面采用防腐处理；反应分析速度快，不需要化学试剂，使用国外进口的深紫外UV LED冷光源，发光测量寿命长，同时采用550nm补偿光源，有效的消除浊度和色度对传感器测量带来的影响，具备更高的可靠性。', parameters: [
            { '输出信号': '支持 Rs-485,MODBUS/RTU 协议' }, { '测虽方法': 'UV 254双波长紫外吸收法' }, { 'COD景程': '0.5~570mg/L equiv. KHP' }, { 'COD精度': '±5%F.S. equiv. KHP' }, { 'COD分辨率': '0.1mg/L或 0.01mg/L equiv. KHP' }, { '浊度量程': '0-100NTU' }, { '重复性': '±1%F.S. equiv. KHP' }, { '工作条件': '0.1~50°C、<0.1MPa' }, { '校准方式': '两点校准' }, { '响应时间': '10秒 T90' }, { '电源': '12或24VDCE0%; 15mA; 200mA毛刷转动时' }, { '尺寸': '直径48mm;长度186mm;另可选配保护罩' }, { '防护等级': 'IP68,水深 10m' }, { '流速': '小于3m/s' }, { '使用寿命': '传感器3年或以上，清洁刷系统18个月或以上' }, { '线筮长度': '5m（默认），可定制' }, { '传感器外壳材料': '316L不锈钢或PVC' }, { '维护和校准频率': '传感器3个月，淸洁刷6个月' }
        ],
        characteristic: []
    },
    {
        id: 26, type: 5, title: '传感器', value: 'OSN-NHN01氨氮传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/nhcgq-product.png' , theory: 'OSN-NHN01是一款基础型常规水质监测数字氨氮传感器；采用工业在线电 极，PVC膜的援离子选择电极制作而成，选择性的测试水中的铉离子含量，响应速度快，测量准确。内置温度传感器，可以自动温度补偿，适合在线长期 监测环境使用。', parameters: [
            { '输出信号': '支持 Rs-485,MODBUS/RTU 协议' }, { '测虽方法': '覆膜离子选择法' }, { '量程': '0〜100.0mg/L （pH范围4~10）' }, { '精度': '±5%F.S.或±3mg/L以大者为准' }, { '分辨率': '0.1mg/L' }, { '工作条件': '0〜4（TC; <0.2MPa' }, { '校准方式': '两点校准' }, { '响应时间': '30秒 T90' }, { '温度补偿': '自动温度补偿（PtlOO）' }, { '电源': '12 或 2 4VDC±10%, 10mA' }, { '防护等级': 'IP68;水深20米' }, { '使用寿命': '传感器1年或以上；膜头6个月' }, { '线缆长度': '5m（默认），可定制' }, { '传感器外壳材料': 'PVC;可定制' }
        ],
        characteristic: []
    },
    {
        id: 27, type: 5, title: '传感器', value: 'OSN-CL01 余氯传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/nhcgq-product.png' , theory: 'OSN-CL01是一款基础型常规水质监测数字余氯传感器；采用工业在线电 极，先进的非膜式恒电压测量原理，无须更换膜片和消耗试剂，性能稳定、 维护简单，具有灵敏度高，响应迅速，测量准确、稳定性高、重复性能优 越、维护简便、多功能等特点，能精确测量溶液中的余氯值。', parameters: [
            { '输出信号': '支持' }, { '测虽方法': '恒电压法' }, { '量程': '0~20.00mg/L(HCIO)' }, { '精度': '±3% 示值或' }, { '分辨率': '0.01mg/L' }, { '工作条件': '0~60°C; <0.2MPa' }, { '校准方式': '两点校准' }, { '响应时间': '30秒 T90' }, { '温度补偿': '自动温度补偿（NTC）' }, { '电源': '12 或 24VDC±10%, 10mA' }, { '防护等级': 'IP68;水深20米' }, { '使用寿命': '传感器1年或以上' }, { '线缆长度': '5m（默认），可定制' }, { '传感器外壳材料': 'PVC;可定制' }
        ],
        characteristic: []
    },
    {
        id: 28, type: 5, title: '传感器', value: 'OSN-BA01自清洁蓝绿藻传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/ba01cgq-product.png' , theory: '在线蓝绿藻传感器，采用荧光法原理，相比传统人工计数法，更高效快 捷，并可在线实时监测。传感器具有更出色的重复性和稳定性。带有自动清 洁刷，可消除气泡、减少沾污对测量的影响，使维护周期更长，长期在线使 用也能保持极佳的稳定性。可对藻类的繁殖起到预警作用。', parameters: [
            { '量程范围': '0-200,000 ' }, { '检出限': '500 cells/mL' }, { '分辨率': '1 cells/mL' }, { '线性度': 'R2>0.999' }, { '防护等级': 'IP68' }, { '最深深度': '水下10米' }, { '传感器接口': '支持RS-485, MODBUS协议' }, { '装配': '投入式' }, { '尺寸': '0>45*175.8mm' }, { '电源信息': 'DC 5-12V,电流＜50mA （非淸洗时）' }, { '探头线缆长度': '10米（默认），可定制' }, { '传感器外壳材料': 'POM' }, { '温度范围': '0~50°C' }, { '自清洁系统': '有' }
        ],
        characteristic: []
    },
    {
        id: 29, type: 5, title: '传感器', value: 'OSN-CH01自清洁叶绿素传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/ba01cgq-product.png' , theory: 'OSN-CH01自清洁叶绿素传感器采用领先的光学技术，带有自动清洁刷，一体化 设计，具有高效率的电源管理、坚固的结构、稳定的传感器性能，免于维 护和频繁校准，可长期在线使用。可用于河流、湖泊、池塘、海洋调查、 养殖业、饮用水源、藻类和浮游植物状况的研究、调查和监测。', parameters: [
            { '量程范围': '0〜400ug/L 或 0-100RFU' }, { '分辨率': '0.1 ug/L' }, { '防护等级': 'IP68' }, { '最深深度': '水下20米' }, { '温度范围': '0~50°C' }, { '传感器接口': '支持RS-485, MODBUS协议' }, { '电源信息': 'DC12V±5%,电流＜50mA （非淸洗时）' }, { '外壳直径': '55><156mm' }, { '功耗': '<1w' }, { '线缆长度': '10米，其他长度可定制' }, { '传感器外壳材料': 'POM' }
        ],
        characteristic: []
    },
    {
        id: 30, type: 5, title: '传感器', value: 'OSN-OIL01自清洁水中油传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/zqjcgq-product.png' , theory: 'OSN-OIL01是一款基础型常规水质监测数字在线水中油传感器；常用的水 中油检测有悬浮法（D/入＜=1）、红外分光光度法（不适合低量程）、紫外分 光光度法（不适合高量程）等。在线水中油传感器，采用荧光法原理，相比 常用的几种方法，荧光法更高效快捷重复性较好，并可在线实时监测。传感 器具有更出色的重复性和稳定性。带有自动清洁刷，可消除气泡、减少沾污 对测量的影响，使维护周期更长，长期在线使用也能保持极佳的稳定性。可 对水中油的污染起到预警作用。', parameters: [
            { '输出信号': '支持' }, { '测量方法': '紫色荧光法' }, { '量程': '0〜50ppm' }, { '精度': '±5%F.S.' }, { '分辨率': '0.01 ppm' }, { '工作条件': '0〜50°C, <0.6MPa' }, { '校准方式': '两点校准' }, { '响应时间': '30秒 T90' }, { '温度补偿': '自动温度补偿（PtIOOO）' }, { '电源': 'DC 12V,电流＜50mA （非清洗时）' }, { '尺寸': '0>42*186.5mm' }, { '防护等级': 'IP68;水深10米' }, { '使用寿命': '传感器3年或以上' }, { '线缆长度': '5m（默认），可定制' }, { '传感器外壳材料': '不锈钢316L' }, { '自清洁系统': '有' }
        ],
        characteristic: []
    },
    {
        id: 31, type: 5, title: '传感器', value: 'OSN-MS600多参数传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/zqjcgq-product.png' , theory: 'OSN-MS600在线多参数自清洗数字数传感器，一 体化设计，产品可靠易用。最多可同时测量7个参数， 可选择传感器类型有溶解氧、pH、ORP、电导率/盐度、氨氮、浊度等，传感器可自由选择更换，采用 RS-485总线，Modbus/RTU通讯协议，数据可直接 传输至采集平台。在线多参数水质传感器上配备自动清洗装置，体积相比同行业更小巧，清洁时间和频率可通过协议设定，以适应不同清洁程度的水质。自动清洗装置可以有效地清洁传感器表面，防止微生物附着，极大的减少维护成本。每支传感器配备快速插拔防水接头，拆装方便。前端的传感器保护罩用来保护内部传感器不受损坏，保护罩四周开有槽孔，可以有效防止大的悬浮颗粒和生物对传感器探头的破坏，同时不会影响测量的 准确性。', parameters: [
            { '溶解氧传感器测量范围': '0-20mg/L' }, { '溶解氧传感器分辨率': '0.01mg/L' }, { '溶解氧传感器精度': '±2%F.S.,±0.5°C' }, { 'ORP传感器测量范围': '-1500m V-+1500m V' }, { 'ORP传感器分辨率': '1mV' }, { 'ORP传感器精度': '±6mV' }, { '浊度传感器测量范围': '0-200NTU/0-1000NTU' }, { '浊度传感器分辨率': '0.1NTU' }, { '浊度传感器精度': '<5%F.S.' }, { '氨氮传感器测量范围': '0-10mg/L 或 0-100mg/L' }, { '氨氮传感器分辨率': '0.01 mg/L(0~10 mg/L) or 0.1mg/L(0~100 mg/L)' }, { '氨氮传感器精度': '±5F.S.或±3mg/L,以大者为准' }, { 'pH传感器测量范围': '0-14pH' }, { 'pH传感器分辨率': '0.01pH' }, { 'pH传感器精度': '±0.1pH' }, { '其他信息': '' }, { '输出方式': 'RS-485(MODBUS/RTU)' }, { '清洁方式': '自动清洁' }, { '电源': '12VDC±5%' }, { '线统长度': '标配5米，其他长度可定制' }
        ],
        characteristic: []
    },
    {
        id: 32, type: 5, title: '传感器', value: 'OSN-N05/OSN-N02/OSN-NCA/OSN-LV/OSN-YF硝酸盐传感器/亚硝酸盐传感器/钙离子传感器/氯离子传感器/氟离子传感器', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/cgq-product.png' , theory: '', parameters: [
            { '输出信号': '支持Rs-485, MODBUS/RTU 协议' }, { '测量方法': '覆膜离子选择法' }, { '量程': '0~10.0mg/L 或0~100.0mg/L (硝酸、亚硝酸、氯离子传感器pH 范围 4~10)' }, { '精度': '±5%F.S.或 ±3mg/L 以大者为准' }, { '分辨率': '0.01mg/L(0~10.00mg/L)' }, { '工作条件': '0~40°C; <0.2MPa' }, { '校准方式': '两点校准' }, { '响应时间': '30秒 T90' }, { '温度补偿': '自动温度补偿（PtIOOO）' }, { '电源': '12 或 24VDC±10%,10mA' }, { '防护等级': 'IP68;水深20米' }, { '使用寿命': '传感器1年或以上； 膜头6个月' }, { '线缆长度': '5m（默认），可定制' }, { '传感器外壳材料': 'POM;可定制' }
        ],
        characteristic: []
    },
    {
        id: 33, type: 6, title: '其他', value: 'OSN-FC600 环境水质监测浮标', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/other33-product.png' , theory: 'OSN-FC600款环境水质监测浮标可以实现视频监控和多种水质参数的同时测量，包括溶解氧、 pH、ORP、电导率和浊度等。数据采集模块通过RS- 485（Modbus/RTU协议）总线采集和处理各数字传感器测量得到的数据，并将数据无线传送到云端，同时根据用户需求可以手机APP等多平台上获取传感器测量值。本款浮标开放多个控制功能，可以远程控制传感器、摄像头等监测设备开关，可以设置定时循环开关等。完整的监测浮标，由浮标体及锚系、监测仪器（摄 像头、温度、溶解氧、浊度、电导率、PH、氨氮、UV COD、叶绿素等）、数据采集传输模块、无线传输模块、供电单元、报警、GPS、远程监测平台组成，无需铺设电缆，可迅速方便地投放在需要监控的地方进行无线监控。OSN-FC600环境水质监测浮标的应用领域广泛，可用 在水产养殖、工业生活污水排放、农业灌溉用水、环境监测等领域。', parameters: [
            { '采集控制模块': '两路独立继电器，一路控制传感器电源，一路控制视 频设备；运行可靠，抗干扰，程序设定时间控制输出' }, { '无线传输模块': '1路Rs485 Modbus/RTU标准协议输岀' }, { '摄像头': '1080P超清,355度全方位旋转' }, { '电池': '锂电池12VDC, 80AH （默认）' }, { '太阳能电池板': '12V/72W (默认)' }, { '供电能力': '定制选型；阴雨天间歐工作15天以上' }, { '位置指示': '警示灯，依照光线定时开启；GPS定位' }, { '监测平台': '云平台；手机APP' }, { '防护罩': 'SUS504滤筒防护罩' }, { '外形尺寸': '550mm*550mm*1200mme' }, { '重量': '20KG' }, { '浮力': '40L' }
        ],
        characteristic: ['可实时实现多路参数在线监测', '可实现实时视频监控', '可实现电池电量监控', '能实现多路参数控制', '数据的存储和报警功能；数据的无线发射功能', '峰值功耗为10W；传感器功耗0.2W/只',]
    },
    {
        id: 34, type: 6, title: '其他', value: 'OSN-MC600   地下窖井管网监测系统', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/other34-product.png' , theory: '本公司推出的OSN-MC600款对窘井液位、流量、水质分析方面的监测，是预警城市内涝，保护城市水位安全的重要手段，防水型多参数水质测控终端不仅可以监测液位，还可扩展PH、电导率、氨氮、COD等参数，实时监 测城市排水管道及窖井的水质状况，协助市政及污水处理厂的相关部门有效确定超标排污口。内置电池和数据采集无线发射集成防水设计，电池更换方便、运维操作简单。本系统低功耗、多功能化设计，为城市排水提供智能化管理。数据采集模块通过RS-485（Modbus/RTU协 议）总线采集和处理各数字传感器测量得到的数据，并将数据无线传送到云端，同时根据用户需求可以手机APP等多平台上获取传感器测量值。OSN-MC600应用领域广泛，可用在海绵城市在线监测预警、市政管网监测预警、入河排污监测预警等。', parameters: [
            { '供电单元': '锂亚电池/可根据客户要求增加（114Ah~380Ah）' }, { '数据处理和发送单元': 'OSN-DTU' }, { '设备防水盒': '240mmx140mmx215mm' }, { '固定单元': '检测系统采用侧壁悬挂固定方式，将固定把手根据水深固定到侧壁。' }, { '监测平台': '云平台；手机APP' }
        ],
        characteristic: ['可实时实现多路参数在线监测', '数据的存储和报警功能；4G无线传输双频900/1800,波特率9600', '低功耗：采集间隔设置（监测时间间隔可更改最低10分钟，更改后续航时间将大幅缩减请知悉）', '设备整体达到IP68防护等级，能够适用各种恶劣的井下条件', '采用锂亚电池的供电方式供电,更换方便;蓄电池接口拔插式，方便人工运维更换,可选配可充电锂电池', '数据采集接口:航空插头，联结方便、安全可靠、防水性强', '天线：玻璃钢天线，简洁美观']
    },
    {
        id: 35, type: 6, title: '其他', value: 'OSN-WD202    二次供水监测系统', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/other35-product.png' , theory: '该供水监测系统可以实现多种水质参数的同时测量，包括余氯、PH和低浊度等。主机通过RS-485（Modbus/RTU协议）总线采集和处理各数字传感器测量得到的数据，并将数据传送给触摸屏显示，触摸屏上显示有多个窗口，可以实时显示各传感器测量的数据。二次供水监测系统经由管道输送待测水样到流通池，水样无添加剂，保证了水质的一致。触摸屏上可以显示多个窗口，可以实时显示各传感器测量的数据，同时可根据用户设定参数来控制系统工作。二次供水监测系统的应用前景广阔，可用在小区供水监 测、泳池水质监测及医疗废水监测等领域。', parameters: [
            { '触摸屏': 'Cortex-A8 CPU, 256M内存，7寸TFT LCD屏幕，分辨率800480' }, { '基站柜子': '480mmx200mmx580mm' }, { '进出管路': '水管材质为进CIPU管，进水管径为10mm；流通池材质为进口亚克力' }, { '电源适配器': '220VAC 50±0.5HZ电源输入，输出DC24V （整体设备供电）' }, { 'DTU': '563，实现数据实时通讯' }, { '监测平台': '云平台；手机APP' }
        ],
        characteristic: ['可实时实现多路参数在线监测', '能实现多路参数控制。', '数据的存储和报警功能；数据的无线发射功能。', '系统额定功耗6.5W,峰值功耗为8W;传感器功耗1W/只']
    },
    {
        id: 36, type: 6, title: '其他', value: 'OBAI系列电磁流量计', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/other36-product.png' , theory: 'OBAI系统电磁流量计适用测量封闭管道中导电液体和浆液的体积流量，如洁净水、污水、各种酸碱盐溶液、泥浆、矿浆、纸浆及食品方面的液体等', parameters: [
            { '执行标准': '电磁流量计设计、生产、检测执行《JJGT033-2007电磁流量计》' }, { '测量精度': '±0.25%, ±0.50%, ±1.0%用户可根据实际工作需要选取经济的测量精度' }, { '连接方式': '法兰式，卡箍式' }, { '额定压力': 'GB0.25Mpa, 0.6Mpa, I.OMpa, 1.6Mpa, 2.5Mpa, 4.0Mpa, 6.4Mpa, 10Mpa, 16Mpa, 25Mpa,40Mpa, 63Mpa' }, { '介质': 'w60*C， w120笆，W150C' }, { '口径': 'DN10-2200' }, { '电极材质': '316L，哈氏（B, C），钛，锂，钳' }, { '内衬材料': '橡胶（氯丁橡胶，聚舗旨橡胶），F4，F46，PFA' }, { '测量管': '不锈钢' }, { '法兰': '碳钢（常规），不锈钢' }, { '表体': '碳钢（常规），不锈钢' }, { '外壳': '碳钢（常规），不锈钢' }, { '表头': '碰铸铝' }, { '供电方式': '220VAC，24VDC，电池供电' }, { '输出信号': '4-20mA，脉冲输出，频率输出' }, { '通讯接口': 'RS-232，RS-485' }, { '通讯协议': 'Hart，Modbus，Profibus' }, { '其他功能': '掉电计时，GPRS无线通讯' }
        ],
        characteristic: []
    },
]

const groupedObjects = productList.reduce((acc, obj) => {
    if (!acc[obj.type]) {
        acc[obj.type] = [];
    }
    acc[obj.type].push(obj);
    return acc;
}, {});

const coreProducts = Object.keys(groupedObjects).map(type => {
    return groupedObjects[type][0];
});

// console.log(coreProducts);

const types = productList.map(p => ({ title: p.title, type: p.type }))
const typeArr = [...new Set(types.map(JSON.stringify))].map(JSON.parse)

const productData = {
    productList,
    coreProducts,
    typeArr,
}

export default productData