import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // name: 'home',
    redirect: '/home',
  },
  {
    path: '/home',
    component: Layout,
    meta: {
      hasCooperation: true
    },
    children: [
      {
        path: '',
        name: '首页',
        component: () => import('../views/home/Home.vue')
      }
    ]
  },
  {
    path: '/product',
    name: '核心产品',
    redirect: '/product/list',
    component: Layout,
    children: [
      {
        path: '/product/list',
        name: '产品列表',
        component: () => import('../views/product/List.vue')
      },
      {
        path: '/product/detail',
        name: '产品详情',
        component: () => import('../views/product/Detail.vue')
      }
    ]
  },
  {
    path: '/news',
    name: '新闻中心',
    redirect: '/news/list',
    component: Layout,
    children: [
      {
        path: '/news/list',
        name: '新闻列表',
        component: () => import('../views/news/List.vue')
      },
      {
        path: '/news/detail',
        name: '新闻详情',
        component: () => import('../views/news/Detail.vue')
      }
    ]
  },
  {
    path: '/about',
    name: '关于',
    redirect: '/about',
    component: Layout,
    meta: {
      hasCooperation: true
    },
    children: [
      {
        path: '',
        name: '关于我们',
        component: () => import('../views/about/AboutUs.vue')
      }
    ]
  },
  {
    path: '/contact',
    name: '联系',
    redirect: '/contact',
    component: Layout,
    meta: {
      hasCooperation: true
    },
    children: [
      {
        path: '',
        name: '联系我们',
        component: () => import('../views/contact/ContactUs.vue')
      }
    ]
  },
  {
    path: '/application',
    name: '应用领域',
    redirect: '/application',
    component: Layout,
    children: [
      {
        path: '',
        name: '应用详情',
        component: () => import('../views/application/Detail.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.BASE_URL,
  routes
})

export default router
