<template>
    <div class="layout">
        <header class="header df aic" :class="hideHeader ? 'hide-header' : 'show-header'">
            <div class="logo-box">
                <router-link to="/"><img class="logo-img" src="@/assets/logo.png" alt=""></router-link>
            </div>
            <transition name="el-zoom-in-bottom">
                <div class="header-middle df aic" v-if="!isSearchOpen">
                    <ul class="nav df">
                        <li> <router-link to="/home">首页</router-link></li>
                        <li><router-link to="/product">核心产品</router-link></li>
                        <li><router-link to="/news">新闻中心</router-link></li>
                        <li><router-link to="/about">关于我们</router-link></li>
                        <li><router-link to="/contact">联系我们</router-link></li>
                    </ul>
                    <div class="tel">{{ companyInfo.tel }}</div>
                    <div class="search" @click="isSearchOpen = true">
                        <i class="el-icon-search"></i>
                    </div>
                    <!-- <div class="language">
                        <span>CN</span>
                        <span>|</span>
                        <span>EN</span>
                    </div> -->
                </div>
            </transition>
            <transition name="el-zoom-in-top">
                <div class="header-search df aic" v-if="isSearchOpen">
                    <i class="el-icon-search"></i>
                    <el-autocomplete class="inline-input" clearable v-model="searchVal" :fetch-suggestions="querySearch"
                        placeholder="请输入产品名称" @select="handleSelect"></el-autocomplete>
                    <i class="el-icon-close" @click="isSearchOpen = false"></i>
                </div>
            </transition>
            <div class="menu-btn" @click="toggleNav">
                <i :class="['iconfont', navExpend ? 'icon-guanbi1' : 'icon-liebiao1']"></i>
            </div>
        </header>
        <router-view></router-view>
        <div class="cooperation" v-show="hasCooperation">
            <div class="cooperation-left">
                <div class="left-title">
                    南京新锐鹏科技期待与您
                    携手共赢
                </div>
                <div class="left-btn df jcc" @click="dialogFormVisible = true">
                    <span>申请合作</span>
                    <i class="iconfont icon-youjiantou"></i>
                </div>
            </div>
            <div class="cooperation-right">
                <img src="http://www.xrphuanbao.com:7599/images/xinruipeng/申请合作.png" alt="">
            </div>
        </div>
        <footer class="footer">
            <div class="footer-top df jcsb">
                <div class="footer-top--left">
                    <img class="footer-logo" src="http://www.xrphuanbao.com:7599/images/xinruipeng/logo_w.png" />
                    <div class="footer-codes df">
                        <div class="code df fdc aic">
                            <img class="code-img" src="http://www.xrphuanbao.com:7599/images/xinruipeng/code.png" alt="">
                            <div class="code-name">公众号</div>
                        </div>
                        <div class="code df fdc aic">
                            <img class="code-img" src="http://www.xrphuanbao.com:7599/images/xinruipeng/code.png" alt="">
                            <div class="code-name">视频号</div>
                        </div>
                        <div class="code df fdc aic">
                            <img class="code-img" src="http://www.xrphuanbao.com:7599/images/xinruipeng/code.png" alt="">
                            <div class="code-name">抖音</div>
                        </div>
                    </div>
                    <div class="footer-contact df fdc">
                        <div class="tel">电话：{{ companyInfo.tel }} </div>
                        <!-- <div class="mail">邮箱：salesdirector@raipun.com </div> -->
                    </div>
                </div>
                <div class="footer-top--right df aic">
                    <dl>
                        <dt @click="toPath('/product')">核心产品</dt>
                        <dd @click="toPath('/product', '#classification', item)" v-for="item in typeArr" :key="item.type">
                            {{ item.title }}</dd>
                    </dl>
                    <dl>
                        <dt>应用领域</dt>
                        <dd>造纸印刷行业</dd>
                        <dd>石油化工行业</dd>
                        <dd>电子元件制造行业</dd>
                        <dd>城镇污水处理厂行业</dd>
                        <dd>食品行业</dd>
                        <dd>电镀行业</dd>
                        <dd>制药行业</dd>
                        <dd>皮革行业</dd>
                    </dl>
                    <dl>
                        <dt @click="toPath('/news')">新闻中心</dt>
                        <dd @click="toPath('/news', '#classification', item)" v-for="(item, index) in newsTypeArr"
                            :key="item.type">{{ item.title }}</dd>
                    </dl>
                    <dl>
                        <dt @click="toPath('/about')">关于我们</dt>
                        <dd @click="toPath('/about', '#company-introduction')">公司简介</dd>
                        <dd @click="toPath('/about', '#imgs-group')">公司愿景</dd>
                        <dd @click="toPath('/about', '#imgs-group')">申请合作</dd>
                    </dl>
                    <dl>
                        <dt @click="toPath('/contact')">联系我们</dt>
                        <dd @click="toPath('/contact', '#company-info')">联系方式</dd>
                        <!-- <dd @click="toPath('/contact', '#technical-support')">技术支持</dd> -->
                    </dl>
                </div>
            </div>
            <div class="footer-down">
                {{ companyInfo.name }} 版权所有 2012-2018 苏ICP备16030849号
            </div>
        </footer>
        <el-dialog title="申请合作" :visible.sync="dialogFormVisible" :width="dialogWidth">
            <el-form :model="form" :hide-required-asterisk="true" :rules="rules" ref="formRef">
                <div class="row df jcsb row1">
                    <div class="form-item">
                        <el-form-item label="" label-width="auto" prop="name">
                            <el-input placeholder="您的称呼：（必填）" v-model="form.name" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item label="" label-width="auto" prop="tel">
                            <el-input v-model="form.tel" placeholder="联系方式：（必填）" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item label="" label-width="auto" prop="mail">
                            <el-input v-model="form.mail" placeholder="电子邮件：（必填）" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="row df jcsb row2">
                    <div class="form-item">
                        <el-form-item label="" label-width="auto">
                            <el-input v-model="form.company" placeholder="公司全称：" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                    <div class="form-item">
                        <el-form-item label="" label-width="auto">
                            <el-input v-model="form.trade" placeholder="所属行业：" autocomplete="off"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="form-item">
                    <el-form-item label="" label-width="auto">
                        <el-input type="textarea" placeholder="您的需求：" v-model="form.requirement"
                            autocomplete="off"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <p>请填写您的信息，我们尽快与您取得联系</p>
                <div class="submit" @click="submit">提交信息</div>
            </div>
        </el-dialog>
        <transition name="el-zoom-in-top">
            <div class="expend-nav" id="expend-nav" v-if="navExpend">
                <ul class="nav">
                    <li><router-link to="/home">首页</router-link></li>
                    <li><router-link to="/product">核心产品</router-link></li>
                    <li><router-link to="/news">新闻中心</router-link></li>
                    <li><router-link to="/about">关于我们</router-link></li>
                    <li><router-link to="/contact">联系我们</router-link></li>
                </ul>
            </div>
        </transition>
        <transition name="el-fade-in-linear">
            <div class="fixed-btns df fdc" v-show="this.top > 100 && !navExpend">
                <div class="return-btn df jcc aic" @click="backHome">
                    <i class="iconfont icon-shouye"></i>
                </div>
                <div class="return-btn df jcc aic" @click="backTop">
                    <i class="iconfont icon-shuangjiantou-shang"></i>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { productData } from '@/assets/js/data.js'
import { companyInfo } from '@/assets/js/data.js'
import { newsData } from '@/assets/js/data.js'
import { mapState } from 'vuex'
const { productList, typeArr } = productData
const { newsList, typeArr: newsTypeArr } = newsData
export default {
    data() {
        return {
            isSearchOpen: false,
            searchVal: '',
            productList,
            typeArr,
            newsList,
            newsTypeArr,
            hasCooperation: false,
            dialogFormVisible: false, // 合作弹框
            dialogWidth: '50%', // 合作弹框宽度
            form: {
                name: '',
                tel: '',
                mail: '',
                company: '',
                trade: '',
                requirement: '',
            },
            rules: {
                name: [{ type: 'string', required: true, message: '请输入姓名', trigger: 'blur' }],
                tel: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
                mail: [{ required: true, message: '请输入电子邮箱', trigger: 'blur' }, { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }],
            },
            navExpend: false, // 导航展开
            // hideHeader: false, // 隐藏头部
            lastScrollTop: 0, // 上一次滚动的位置
            top: 0,
            language: 'zn', //语种
            companyInfo
        }
    },
    computed: {
        ...mapState(['hideHeader'])
    },
    watch: {
        '$route': {
            handler(to, from) {
                this.hasCooperation = this.$route.matched[0].meta?.hasCooperation
                // this.navExpend = false
                this.isSearchOpen = false // 路由变化了就关闭搜索框
            },
            immediate: true,
            deep: true
        },
        'navExpend': {
            handler(newVal, oldVal) {
                document.body.style.overflow = newVal ? 'hidden' : 'auto'
            },
            immediate: true,
        },
        top(newVal, oldVal) {
            if (newVal > 100) {
                if (newVal > oldVal) {
                    // this.hideHeader = true
                    this.$store.commit('CHANGE_HEADER_STATE', true)
                    // console.log('向下滚动')
                } else {
                    // this.hideHeader = false
                    this.$store.commit('CHANGE_HEADER_STATE', false)
                    // console.log('向上滚动')
                }
            } else {
                // this.hideHeader = false
                this.$store.commit('CHANGE_HEADER_STATE', false)
            }
        }
    },
    methods: {
        querySearch(queryString, cb) {
            var productList = this.productList
            var results = queryString ? productList.filter(this.createFilter(queryString)) : productList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (item) => {
                return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
            };
        },
        handleSelect(item) {
            if (this.$route.path != '/product/detail') {
                this.$router.push({
                    path: '/product/detail',
                    query: { id: item.id }
                })
            }
            this.$store.commit('CHANGE_PRODUCT_ID', item.id)
        },
        submit() {
            this.$refs.formRef.validate(valid => {
                if (valid) {
                    this.dialogFormVisible = false
                    this.$message({
                        message: '提交成功',
                        type: 'success'
                    })
                } else {
                    console.log('error submit!!');
                }
            })
        },
        hideMenu(event) { // 点击菜单或菜单子元素,隐藏菜单
            if (this.navExpend) {
                // 获取下拉菜单的DOM节点
                const menu = document.getElementById('expend-nav');
                // console.log(menu, 'menu');
                // 检查点击目标是否是下拉菜单或其子元素
                if (menu && menu.contains(event.target)) {
                    // 点击目标是下拉菜单或其子元素，隐藏菜单
                    this.navExpend = false;
                }
            }
        },
        toggleNav() { // 移动端导航显示与隐藏
            this.navExpend = !this.navExpend
        },
        handleScroll() { // 监听滚动
            this.top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
            // console.log(this.top, 'top');
        },
        backHome() {
            this.$router.push({
                path: '/home'
            }, () => { })
            this.hideHeader = false
        },
        backTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        toPath(url, hash, query) {
            this.$router.push({
                path: url,
                hash,
                query: { type: query?.type }
            }, () => { })
        },
        setDialogWidth() {
            // const screenWidth = document.documentElement.clientWidth
            const screenWidth = window.innerWidth
            // console.log(screenWidth, 'screenWidth');
            if (screenWidth <= 1024) {
                this.dialogWidth = '90%'
            } else {
                this.dialogWidth = '50%'
            }
        },
        languageAuto() { // 中英文切换
            this.language == 'zh' ? (this.language = 'en') : (this.language = 'zh')
            localStorage.setItem('language', `{"language":"${this.language}"}`)
            if (this.language == 'zh') {
                console.log('转为中文')
                this.$translate.changeLanguage('chinese_simplified')
            }
            if (this.language == 'en') {
                console.log('转为英文')
                this.$translate.changeLanguage('english')
            }
            this.$translate.execute() //进行翻译
        },
    },
    created() {
    },
    mounted() {
        this.setDialogWidth()
        document.addEventListener('click', this.hideMenu)
        window.addEventListener('resize', this.setDialogWidth)
        window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.hideMenu)
        document.removeEventListener('scroll', this.handleScroll)
    }
}
</script>
<style lang="scss" scoped>
.layout {
    .hide-header {
        transform: translateY(-100%);
        transition: all .3s;
    }

    .show-header {
        transform: translateY(0);
        transition: all .3s;
    }

    .header {
        height: 1.35rem;
        position: sticky;
        top: 0;
        z-index: 110;
        background-color: #fff;
        padding: .18rem 1.2rem;
        border-bottom: 0.01rem solid #CFD4DB;

        .menu-btn {
            display: none;
        }

        .logo-box {
            width: 1.92rem;
            height: 1rem;
            // margin-right: 4.46rem;

            .logo-img {
                width: 100%;
                height: 100%;
            }
        }

        .header-middle {
            position: absolute;
            width: max-content;
            left: 33%;
        }

        .nav {
            gap: .69rem;
            font-size: .24rem;
            margin-right: 1.5rem;

            a {
                font-weight: 400;
                color: #011405;
                display: block;
                padding: .18rem 0;

                &::after {
                    position: absolute;
                    bottom: -0.06rem;
                    left: 50%;
                    transform: translateX(-50%);
                    display: block;
                    content: '';
                    width: .78rem;
                    height: .06rem;
                    // background: #00A0E8;
                }

                &.router-link-active {
                    position: relative;

                    &::after {
                        background: #00A0E8;
                        transition: background-color 0.5s ease-in;
                    }
                }
            }
        }

        .tel {
            font-weight: 500;
            font-size: .36rem;
            color: #011405;
            margin-right: 1.89rem;
        }

        .search {
            font-size: .28rem;
            cursor: pointer;
            margin-right: .33rem;
        }

        .language {
            font-size: 0.21rem;
            color: #011405;

            span {
                margin-right: .1rem;
            }
        }

        .header-search {
            position: absolute;
            // top: 50%;
            left: 33%;
            // transform: translateX(-50%);
            // transition: all 2s cubic-bezier(0.215, 0.61, 0.355, 1);
            width: 11rem;
            font-size: .28rem;

            .el-icon-close {
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }
            }

            ::v-deep .el-autocomplete {
                width: 100%;

                .el-input__inner {
                    border: none;
                    font-size: .28rem;
                }
            }
        }

    }

    .cooperation {
        display: flex;

        .cooperation-left {
            height: 3.3rem;
            width: 50%;
            padding: .48rem 3.57rem .48rem 3.15rem;
            background: #00A0E8;

            .left-title {
                font-weight: 600;
                font-size: .48rem;
                color: #FFFFFF;
                margin-bottom: .39rem;
            }

            .left-btn {
                width: 2.04rem;
                height: .6rem;
                line-height: .6rem;
                background: rgba(255, 255, 255, 0.1);
                border: .01rem solid rgba(255, 255, 255, 0.5);
                gap: .18rem;
                font-size: .28rem;
                color: #FFFFFF;
                cursor: pointer;

                &:hover {
                    opacity: 0.8;
                }

                .icon-youjiantou {
                    font-size: .2rem;
                    color: #FFFFFF;
                }
            }
        }

        .cooperation-right {
            height: 3.3rem;

            img {
                height: 100%;
            }
        }
    }

    .footer {
        background: url('http://www.xrphuanbao.com:7599/images/xinruipeng/footer.png') no-repeat;
        background-size: cover;
        // padding-top: 1.2rem;

        .footer-top {
            height: 6.64rem;
            border-bottom: .01rem solid rgba(255, 255, 255, 0.1);
            padding: 1.2rem 3.15rem 0;
            color: #CFD4DB;

            .footer-top--left {
                .footer-logo {
                    width: 1.45rem;
                    height: .75rem;
                    margin-bottom: .33rem;
                }

                .footer-codes {
                    gap: .3rem;

                    .code {
                        gap: .28rem;
                        margin-bottom: .69rem;

                        .code-img {
                            width: 1.3rem;
                            height: 1.3rem;
                        }

                        .code-name {
                            font-size: .24rem;
                        }
                    }
                }

                .footer-contact {
                    font-size: .24rem;
                    gap: .18rem;
                }
            }

            .footer-top--right {
                gap: .69rem;
                font-weight: 400;
                font-size: .24rem;
                align-items: flex-start;
                padding-top: .21rem;

                dt {
                    margin-bottom: .38rem;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }

                dd {
                    margin-bottom: .14rem;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }

        .footer-down {
            height: 1.05rem;
            line-height: 1.05rem;
            text-align: center;
            font-size: .18rem;
            color: #6D6D6D;
        }
    }

    // 申请合作弹框
    ::v-deep .el-dialog__header {
        text-align: center;
        padding: .3rem 0;
        padding-bottom: 0;

        .el-dialog__title {
            display: inline-block;
            font-weight: 500;
            font-size: .48rem;
            color: #011405;
            padding-top: .27rem;
        }

        .el-dialog__headerbtn .el-dialog__close {
            color: #011405;
        }
    }

    ::v-deep .el-dialog__body {
        padding: .3rem .69rem .69rem;
    }

    .form-item {
        border: .02rem solid #011405;
        border-radius: 2px;
    }

    .row {
        gap: .39rem;
        margin-bottom: .3rem;
    }

    .row1 {
        .form-item {
            width: 33%;
        }
    }

    .row2 {
        .form-item {
            width: 50%;
        }
    }

    ::v-deep .el-form-item {
        margin: 0;
        padding: .13rem .18rem;

        .el-form-item__content {
            line-height: 1;

            .el-input__inner {
                border: none;
                height: auto;
                line-height: 1;
                padding: 0;
                font-size: .24rem;

                &::placeholder {
                    color: #011405;
                }
            }

            .el-form-item__error {
                font-size: .18rem;
                padding-top: 1em;
            }

            .el-textarea__inner {
                border: none;
                padding: 0;
                font-size: .24rem;

                &::placeholder {
                    color: #011405;
                }
            }
        }

    }

    ::v-deep .el-dialog__footer {
        padding: 0;
    }

    .dialog-footer {
        text-align: center;
        padding-bottom: 1.2rem;

        p {
            font-weight: 400;
            font-size: .18rem;
            color: #011405;
            line-height: .25rem;
            margin-bottom: .12rem;
        }

        .submit {
            width: 1.32rem;
            height: .54rem;
            line-height: .54rem;
            background: #011405;
            text-align: center;
            font-size: .24rem;
            color: #FFFFFF;
            margin: 0 auto;
            cursor: pointer;

            &:hover {
                opacity: 0.8
            }
        }
    }

    .fixed-btns {
        position: fixed;
        bottom: 15%;
        right: 1%;
        gap: .09rem;
        z-index: 1000;

        .return-btn {
            width: .8rem;
            height: .8rem;
            background: #E7E7E7;
            cursor: pointer;

            &:hover {
                opacity: .8;
            }

            .iconfont {
                color: #011405;
                font-size: .38rem;
            }

            .icon-shouye {
                font-size: .33rem;
            }
        }
    }
}


@media screen and (min-width: 368px) and (max-width:1024px) {

    .layout {
        position: relative;

        .expend-nav {
            width: 100vw;
            height: 100%;
            position: fixed;
            top: 2.4rem;
            left: 0;
            bottom: 0;
            background-color: #fff;
            z-index: 200;

            ul {
                position: relative;
                height: 100vh;
                // overflow-y: auto;
                padding: .9rem .75rem;
                z-index: 300;

                li {
                    a {
                        font-weight: 400;
                        font-size: 0.42rem;
                        color: #011405;
                    }

                    margin-bottom: .9rem;
                }
            }
        }

        .header {
            height: 2.4rem;
            justify-content: space-between;
            padding: .6rem .75rem;

            .logo-box {
                width: 2.34rem;
                height: 1.26rem;
                // margin-right: 4.46rem;

                .logo-img {
                    width: 100%;
                    height: 100%;
                }
            }

            .header-middle {
                left: inherit;
                right: 2.32rem;

                .nav,
                .tel {
                    display: none;
                }

                .el-icon-search {
                    font-size: .8rem;
                }

                .language {
                    display: none;
                }
            }

            .header-search {
                width: 5.5rem;
                font-size: .8rem;

                ::v-deep .el-autocomplete {
                    .el-input {
                        display: flex;
                        align-items: center;
                    }

                    .el-input__inner {
                        border: none;
                        font-size: .5rem;
                        height: 100%;
                        line-height: 1;
                    }
                }
            }

            .menu-btn {
                display: inline-block;

                .iconfont {
                    font-size: .8rem;
                }
            }
        }

        .cooperation {
            flex-wrap: wrap;
            // height: max-content;

            .cooperation-left {
                width: 100%;
                padding: .48rem 0 .48rem 1.05rem;
                background: #00A0E8;
                // height: 3.3rem;

                .left-title {
                    width: 5.28rem;
                    font-weight: 600;
                    font-size: .48rem;
                    color: #FFFFFF;
                    margin-bottom: .39rem;
                }

                .left-btn {
                    width: 2.04rem;
                    height: .6rem;
                    line-height: .6rem;
                    background: rgba(255, 255, 255, 0.1);
                    border: .01rem solid rgba(255, 255, 255, 0.5);
                    gap: .18rem;
                    font-size: .24rem;
                    color: #FFFFFF;
                    cursor: pointer;

                    &:hover {
                        opacity: 0.8;
                    }

                    .icon-youjiantou {
                        font-size: .2rem;
                        color: #FFFFFF;
                    }
                }
            }

            .cooperation-right {
                width: 100%;

                // height: 3.3rem;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .footer {
            // background: url('http://www.xrphuanbao.com:7599/images/xinruipeng/footer.png') no-repeat;
            // background-size: cover;

            .footer-top {
                // height: 6.64rem;
                // border-bottom: .01rem solid rgba(255, 255, 255, 0.1);
                padding: 0;
                padding-top: .75rem;
                padding-left: 1.05rem;
                // color: #CFD4DB;

                .footer-top--left {
                    .footer-logo {
                        width: 1.92rem;
                        height: .99rem;
                        margin-bottom: .3rem;
                    }

                    .footer-codes {
                        gap: .54rem;

                        .code {
                            gap: .15rem;
                            margin-bottom: .75rem;

                            .code-img {
                                width: 1.92rem;
                                height: 1.92rem;
                            }

                            .code-name {
                                font-size: .3rem;
                            }
                        }
                    }

                    .footer-contact {
                        font-size: .3rem;
                        gap: .18rem;
                    }
                }

                .footer-top--right {
                    display: none;
                }
            }

            .footer-down {
                // height: 1.05rem;
                // line-height: 1.05rem;
                // text-align: center;
                font-size: .27rem;
                // color: #6D6D6D;
            }
        }

        .fixed-btns {
            position: fixed;
            bottom: 10%;
            right: 1%;
            gap: .09rem;
            z-index: 1000;

            .return-btn {
                width: 1.2rem;
                height: 1.2rem;

                .iconfont {
                    font-size: .48rem;
                }

                .icon-shouye {
                    font-size: .44rem;
                }
            }
        }
    }

}

@media screen and (max-width:820px) {
    .layout {

        // 申请合作弹框
        ::v-deep .el-dialog__header {
            padding: .75rem 0;
            padding-bottom: 0;

            .el-dialog__title {
                font-size: .54rem;
                padding-top: 0;
            }

            .el-dialog__headerbtn .el-dialog__close {
                color: #011405;
                font-weight: 500;
                font-size: .42rem;
            }
        }

        ::v-deep .el-dialog__body {
            padding: .45rem .75rem .75rem;
        }

        ::v-deep .el-form-item {
            margin: 0;
            padding: .42rem .3rem;

            .el-form-item__content {

                .el-input__inner {
                    font-size: .36rem;
                }

                .el-form-item__error {
                    font-size: .22rem;
                    padding-top: .8em;
                }

                .el-textarea__inner {
                    font-size: .36rem;
                }
            }
        }

        .row {
            flex-direction: column;
            gap: .45rem;
            margin-bottom: .45rem;
        }

        .row1,
        .row2 {
            .form-item {
                width: 100%;
            }
        }

        .dialog-footer {
            padding-bottom: .9rem;

            p {
                font-size: .36rem;
                line-height: .49rem;
                margin-bottom: .3rem;
            }

            .submit {
                width: 2.85rem;
                height: .84rem;
                line-height: .84rem;
                font-size: .3rem;
                color: #FFFFFF;
            }
        }
    }
}

// @media screen and (orientation:landscape) {

//     // 横屏时
//     .layout {
//         .expend-nav {

//             ul {
//                 overflow-y: auto;
//             }
//         }
//     }
// }
</style>
