import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/font/iconfont.css'
import '@/assets/css/normalize.scss'
import '@/assets/css/reset.scss'

// 引入适配
import '@/assets/js/flexible.js'

// 翻译
import translate from 'i18n-jsautotranslate'
// 锚点跳转
import anchor from '@/utils/anchor.js'
Vue.prototype.$anchor = anchor
translate.setUseVersion2() //v2.x 版本
translate.selectLanguageTag.show = false //是否显示切换栏
translate.listener.start() //监控页面动态渲染的文本进行自动翻译
Vue.prototype.$translate = translate

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
