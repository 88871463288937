import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hideHeader: false,
    id: localStorage.getItem('id') || null
  },
  getters: {
    GET_PRODUCT_ID() {
      return localStorage.getItem('id')
    }
  },
  mutations: {
    CHANGE_HEADER_STATE(state, payload) {
      state.hideHeader = payload
    },
    CHANGE_PRODUCT_ID(state, payload) {
      state.id = payload
      localStorage.setItem('id', payload)
    },
  },
  actions: {
  },
  modules: {
  }
})
