const newsList = [
    {
        id: 1, type: 1, typeTitle: '行业新闻', date: '2023.07.25', site: '福建日报', title: '《生物多样性公约》第十五次缔约方大会主席、生态环境部部长黄润秋宣布牵头发起“昆蒙框架”实施倡议',
        content: [
            { text: '2023年12月9日，在《联合国气候变化框架公约》第二十八次缔约方大会（COP28）“自然日”期间，生态环境部部长黄润秋视频出席推动实施“昆明-蒙特利尔全球生物多样性框架”（以下简称“昆蒙框架”）下“3030目标”高级别活动，并以《生物多样性公约》第十五次缔约方大会（COP15）主席身份宣布牵头发起“昆蒙框架”实施倡议。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/新闻详情1.png' },
            { text: '黄润秋表示，气候变化、生物多样性丧失给人类生存与发展带来严峻挑战，在去年12月召开的COP15第二阶段会议上，中国作为主席国引领推动达成了包括“昆蒙框架”在内的具有里程碑意义的一揽子决定，为全球生物多样性治理擘画了蓝图、设定了目标、明确了路径、凝聚了力量。其中，“3030目标”对协同推进保护生物多样性和应对气候变化、增强地球生命共同体气候韧性具有重要作用，呼吁各缔约方平衡推进《生物多样性公约》三大目标，奋力扭转全球生物多样性丧失趋势。-蒙特利尔全球生物多样性框架”（以下简称“昆蒙框架”）下“3030目标”高级别活动，并以《生物多样性公约》第十五次缔约方大会（COP15）主席身份宣布牵头发起“昆蒙框架”实施倡议。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/新闻详情2.png' },
            { text: '黄润秋宣布，为了进一步凝聚各方落实“昆蒙框架”的政治意愿，中方正式牵头发起“昆蒙框架”实施倡议，愿与国际社会一道，携手应对生物多样性丧失、气候变化等全球性挑战，共同推动COP28取得成功，共建人与自然和谐共生的地球家园。-蒙特利尔全球生物多样性框架”（以下简称“昆蒙框架”）下“3030目标”高级别活动，并以《生物多样性公约》第十五次缔约方大会（COP15）主席身份宣布牵头发起“昆蒙框架”实施倡议。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/新闻详情3.png' },
            { text: '“昆蒙框架”实施倡议旨在打造一个各缔约方和利益攸关方自愿参与、体现政治意愿的合作平台，推动“昆蒙框架”全面落实。中方作为COP15主席国联合柬埔寨、加拿大、智利、哥斯达黎加、刚果（金）、德国、马尔代夫等缔约方以及联合国环境规划署、《生物多样性公约》秘书处等国际组织共同发起“昆蒙框架”实施倡议。目前联合国开发计划署、气候债券倡议组织、自然资源保护协会、大自然保护协会、世界经济论坛和世界自然基金会等合作伙伴已加入倡议。-蒙特利尔全球生物多样性框架”（以下简称“昆蒙框架”）下“3030目标”高级别活动，并以《生物多样性公约》第十五次缔约方大会（COP15）主席身份宣布牵头发起“昆蒙框架”实施倡议。' }]
    },
    {
        id: 2, type: 1, typeTitle: '行业新闻', date: '2024.01.11', site: '中国环境APP', title: '潇湘生态行丨专家献智护生态，纾困解难助发展',
        content: [
            { text: '2023年，生态环境部实施了强化重大项目环评服务、生态环境科技帮扶等30项举措巩固和拓展经济运行持续回升向好，以高品质生态环境支撑高质量发展。为打通服务企业最后一公里，帮助企业形成污染治理技术解决方案，湖南省生态环境厅组织开展了“百名专家下基层”帮扶活动，特邀专家150余人次，深入100余个园区、160余家企业进行现场踏勘或座谈交流，为240余个问题进行政策解读和提供技术支撑，实现了14个市州、139家园区全覆盖。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/news2.png'},
            { text: '“建议企业进行车间分区管理，并联合设备生产厂家对移动式焊接烟尘净化器进行特殊改造并完善工人操作规范。”在湖南省临澧高新区金华天机械制造有限公司内，专家帮扶组围绕企业烟尘净化器实际运行中处理效率低，达不到预期效果等问题展开了现场交流讨论，并针对产业园区内制约当地高质量发展生态环境保护难题“把脉问诊”，提出科学合理、可操作的建议。' },
            { text: '“各地产业结构不同，存在的问题也不尽相同，在帮扶过程中，我们主要考虑的是园区和企业的实际需求。”湖南省生态环境事务中心（以下简称事务中心）党委书记、主任刘翔告诉记者。' },
            { text: '打通难点需要技术，更需要经验。刘翔向记者介绍，依托湖南省生态环境保护专家委员会，事务中心邀请全国50余名生态环境领域知名专家和省内300余名生态环境相关领域专家打造“智库”平台，根据地方需求为企业送政策、送技术、送方案。' },
            { text: '借力动态性、差异化制定帮扶清单，精准确定不同领域的专家组成专家帮扶组赴现场答疑解惑、纾困解难。“帮扶活动目前已经形成常态化帮扶机制，除现场帮扶外，事务中心还采取线上交流、函询等多种方式开展技术服务，为各地企业排忧解难。创新性将专家帮扶助推高质量发展与省生态环境保护例行督察有机结合，组织相关厅局、湖南省钢铁集团有限公司，特邀姜涛院士等国内钢铁行业知名专家，召开专题咨询会，协同推进湖南钢铁行业高质量发展与生态环境高水平保护。”刘翔说。' },
            { text: '产业园区是经济发展的主体，也是生态环境保护的主阵地，要实现高质量发展，做好产业园区内生态环境保护工作是关键。“在帮扶的过程中，事务中心紧盯湖南省空气环境质量改善、地表水断面达标、重金属断面水质改善、矿涌水污染治理等情况，助力污染防治攻坚战提效达标提档进位。”事务中心研究部部长曹建兵向记者介绍。“对现场收集又无法当场解决的问题，与专家帮扶组协商后第一时间形成调研报告，报送省厅相关处室、单位进行会商，从政策和技术等层面给出可行性方案。' },
            { text: '截至2023年12月底，事务中心已形成《湘西州张排汇口万溶江段、民安街道三湾塘村国控断面水质超标情况帮扶报告》《娄底市矿涌水治理帮扶建议》《郴州市东河秧溪桥断面重金属超标问题帮扶建议》等帮扶报告。其中，湘西州万溶江张排断面通过帮扶后，由劣Ⅴ类水质改善至目前的Ⅲ类水质。' }]
    },
    {
        id: 3, type: 1, typeTitle: '行业新闻', date: '2024.05.04', site: '河北生态环境发布', title: '承德推进河湖库“四乱”问题清理整治', content: [
            { text: '近日，河北省承德市纵深推进河湖库“四乱”问题清理整治工作，坚决遏制“四乱”问题增量，巩固和提升河湖库行洪功能、生态支撑功能、资源供给功能，牢牢守住安全底线。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/news3.png' },
            { text: '严格属地管理，切实压实各级各部门责任。将纵深推进河湖库“四乱”问题清理整治工作纳入党委、政府重要议事日程抓紧、抓好、抓到位，压实各级各部门责任，形成纵向贯通、横向覆盖的清理整治责任链，确保排查整改工作及时有效。' },
            { text: '聚焦清理重点，全面排查整改落实到位。以大中型水库、骨干行洪河道、山区河道等为重点，6月底前对能够立行立改的要全部落地见效，11月底前对需要一定时间整改的要取得突破性成果，12月底前完善河湖库“清四乱”制度化、规范化、常态化工作机制，确保问题不漏项、整改落到位。' },
            { text: '加强日常监管，切实做好源头预防管控。严格涉河建设项目审批和监管，从源头上遏制垃圾入河入湖入库；严格事责清单管理，开展网格化巡查、动态监管；严厉打击河道私挖乱采，依法依规处置“四乱”苗头性问题。' },
            { text: '同时，承德还将实施联动督查机制，开展暗访和检察监督，督促相关职能部门依法认真履行职责。实施督办问效机制，开展专项督办、进驻式督办、挂牌督办，推动清理整治工作落地落实。实施公众监督机制，畅通投诉举报渠道，开设河湖库曝光台，警示一片、教育一方。实施责任追究机制，视情况进行提醒、约谈、通报批评，问题严重的依纪依法处理。' },
        ]
    },
    // { id: 4, type: 1, typeTitle: '行业新闻', date: '2023.08.01', site: '陕西日报', title: '心怀“国之大者” 持续推进生态环境保护', content: '生态环境是关系党的使命宗旨的重大政治问题，也是关系民生福祉的重大社会问题。全省生态环境保护大会暨秦岭生态环境保护会议强调……', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/新闻列表4.png' },
    // { id: 5, type: 1, typeTitle: '行业新闻', date: '2023.08.01', site: '陕西日报', title: '心怀“国之大者” 持续推进生态环境保护', content: '生态环境是关系党的使命宗旨的重大政治问题，也是关系民生福祉的重大社会问题。全省生态环境保护大会暨秦岭生态环境保护会议强调……', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/新闻列表5.png' },
    {
        id: 6, type: 2, typeTitle: '企业新闻', date: '2016.02.08', site: '', title: '与雪花啤酒（吉林）有限公司合作取得圆满成功', content: [
            { text: '雪花啤酒有限公司是一家专业生产，销售啤酒的专业公司。雪花啤酒产品总销量连续多年领先于中国其他啤酒品牌，牢牢占据了啤酒行业的领导地位。', src: 'http://www.xrphuanbao.com:7599/images/xinruipeng/xrp/news4.png' },
            { text: '雪花啤酒（吉林）有限公司积极响应环保部要求，对生产废水进行处理，监测，做一个有责任心的企业。经过对多家供应商，从产品质量，产品性能，安装培训，售后服务等多方面的严格筛选，最终，该公司购买了我司产品--氨氮在线监测仪，用于对生产废水中的氨氮进行实时监测。合作达成后，我司技术人员远赴东北吉林，进行现场安装调试，同时对客户进行了产品使用，部件拆卸，日常保养等方面的培训。' },
            { text: '吉林地处中国东北，天气常年较为严寒。我司产品在严寒的地理环境中依旧能够保持高效地运作，也是对我司产品质量的佐证。感谢雪花啤酒（吉林）有限公司的信任与支持，同时欢迎更多客户关注我司产品。' },
            {

            }]
    },
]

const types = newsList.map(p => ({ title: p.typeTitle, type: p.type }))
const typeArr = [...new Set(types.map(JSON.stringify))].map(JSON.parse)
// console.log(typeArr, '新闻type');
const newsData = {
    newsList,
    typeArr
}
export default newsData