import productData from './productData'
import newsData from './newsData'
import companyInfo from './companyInfo'

const data = {
    productData,
    newsData,
    companyInfo
}

export default data