export default function anchor() {
    const hash = this.$route.hash
    this.$nextTick(() => {
        if (hash) {
            const position = document.getElementById(hash.substring(1))
            // console.log(position, 'position');
            position.scrollIntoView({
                behavior: 'smooth'
            })
        }
    })
}